@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';

.container {
	isolation: isolate;
	background-color: $neutral-black;
	padding: 3.75rem 0;

	button[aria-label='Previous slide'] {
		margin-left: 2rem;
	}

	button[aria-label='Next slide'] {
		margin-right: 2rem;
	}
}

.quote_container {
	display: flex !important;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	margin-inline: auto;
	padding: 0 2rem;
	min-height: 14rem;

	margin-bottom: 2rem;
}

.text_container {
	display: flex;
	align-items: center;
	flex: 1;
	p {
		@include subheading2;
		margin-bottom: 2rem;
		text-align: center;
		max-width: 30ch;
		font-weight: normal;
	}
}

.caption {
	display: flex;
	align-items: center;
	justify-content: center;

	p {
		@include body5;
		margin-left: 0.75rem;
	}
}

.image_container {
	position: relative;
	height: 2.25rem;
	width: 2.25rem;
	overflow: hidden;
	border-radius: 100%;

	img {
		// crummy hack to remove the white border around the image
		// would be better to use square images and then just round the corners
		width: 105%;
		height: 105%;
		max-height: 105%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
