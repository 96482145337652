@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';
@import 'styles/layout-tokens';

.container {
	background-color: $neutral-white;
	outline: $neutral-white solid 1px;
	color: $neutral-black;
	padding: calc($nav-height-mobile + 2rem) 1.5rem 2rem;
	height: var(--height);
	width: 100vw;

	@include md-up {
		padding: 3.75rem 1.5rem;
		height: unset;
	}

	display: flex;
	justify-content: center;
	align-items: center;
}

.inner {
	max-width: 850px;
	margin: auto;
	width: 100%;

	display: grid;

	height: 100%;
	grid-template-rows: auto 1fr;
}

.text {
	display: flex;
	flex-direction: column;
	h2 {
		@include heading5;
		max-width: 13ch;
		margin-bottom: 1rem;
	}

	p {
		@include body4;
		margin-bottom: 2rem;
		max-width: 30ch;
		color: $neutral-1;
	}

	@include sm-up {
		h2 {
			max-width: unset;
		}

		p {
			max-width: 60ch;
		}
	}

	@include md-up {
		text-align: center;
		align-items: center;

		h2 {
			@include heading4;
			max-width: 21ch;
			margin-bottom: 1rem;
		}

		p {
			@include body3;
			color: $neutral-2;
			margin-bottom: 2rem;
			max-width: 55ch;
		}

		max-width: unset;
	}
}

.no_mobile {
	display: none;
	@include sm-up {
		display: inline;
	}
}

.video_container {
	position: relative;

	video {
		position: absolute;
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	@include md-up {
		margin-top: 2rem;
		width: unset;

		video {
			position: relative;
		}

		img {
			min-height: auto;
			height: auto;
		}
	}

	.temp1 {
		width: min(12vw, 120px);
		height: min(12vw, 120px);
		position: absolute;
		left: 5%;
		top: 30%;
		min-height: 0;

		svg {
			width: min(12vw, 120px);
			height: min(12vw, 120px);
		}
	}

	.temp2 {
		width: min(12vw, 120px);
		height: min(12vw, 120px);
		position: absolute;
		right: 5%;
		top: 10%;
		min-height: 0;

		svg {
			width: min(12vw, 120px);
			height: min(12vw, 120px);
		}
	}

	p {
		@include body1;
		color: $neutral-white;
		position: absolute;
		inset: 0;
		text-align: center;
		display: grid;
		place-content: center;

		@include sm-down {
			@include body2;
		}

		@include xs-only {
			@include body6;
		}

		margin-top: 0.5em !important;
	}
}

.temp_container {
	position: absolute;
	inset: 0;
}
