@import 'styles/mixins/media-queries';
@import 'styles/colors-2023';
@import 'styles/layout-tokens';
@import 'styles/mixins/fonts-2023';
@import 'styles/mixins/button-styles-2023';

.modal_outer {
	overflow: hidden;
	position: fixed;

	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	height: 100vh;
	z-index: 1300;

	transition: all 0.5s ease;
}

.modal_handle {
	width: 100%;
	height: 100%;
}

.modal_wrapper {
	background-color: #fff;
	color: #000;
	height: 100%;
	max-width: 75vw;
	right: 0;
	position: absolute;
	display: flex;
	justify-content: center;

	overflow-y: auto;

	border-radius: 1.5rem 0 0 1.5rem;

	@include sm-down {
		max-width: calc(100vw - 8rem);
	}

	@include xs-only {
		right: auto;
		bottom: 0;
		width: 100%;
		max-width: unset;
		max-height: calc(100% - 8rem);

		border-radius: 1.5rem 1.5rem 0 0;
	}
}

.close_button {
	position: absolute;
	z-index: 10;
	right: 1rem;
	top: 1rem;
	background: none;
	padding: 0;
	margin: 0;
	border: none;

	cursor: pointer;

	opacity: 0.6;

	&:focus-visible,
	&:hover {
		opacity: 1;
	}

	svg {
		height: 2rem;
		width: 2rem;
	}
}

.handle_outer {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);

	width: 2.25rem;
	height: 5rem;

	border: none;
	padding: 0;
	margin: 0;

	cursor: grab;

	> div {
		position: absolute;
		left: 1rem;

		width: 0.125rem;
		height: 3rem;
		border-radius: 50rem;
		top: 50%;
		transform: translateY(-50%);

		background: $neutral-black;
	}

	transition: all 0.25s ease;

	opacity: 0.4;

	&:hover {
		opacity: 1;
	}

	@include xs-only {
		left: 50%;
		transform: translateX(-50%);
		top: 1rem;

		height: 2.25rem;
		width: 5rem;

		> div {
			left: 50%;
			transform: translateX(-50%);
			top: 0;

			width: 3rem;
			height: 0.125rem;
		}
	}
}

.modal_inner {
	overflow-y: auto;
}

.full_bleed_inner {
	overflow-y: auto;
}

.padded {
	padding: 5rem 5rem 0;

	@include xs-only {
		padding: 2rem 2rem 0;
	}
}

.title {
	@include subheading2;

	max-width: 26ch;
	margin: auto auto 2rem;

	text-align: center;
}

.description {
	@include body6;
	text-align: center;
	max-width: 58ch;
	margin: auto auto 2rem;
}

.models {
	list-style: none;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 1rem;

	padding: 0;
	margin: 0;
}

.model_info {
	position: relative;
	background-color: $neutral-7;
	border-radius: 1rem;
	padding: 2rem;

	max-width: 24rem;
	width: 100%;

	h3 {
		@include subheading4;
		margin-bottom: 1rem;
	}

	em {
		position: absolute;
		top: 1rem;
		right: 1rem;

		@include body8;
		color: #d9682a;

		background-color: $neutral-white;
		padding: 0.1rem 0.3rem;
		border-radius: 6px;
	}
}

.features_list {
	list-style: none;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	padding: 0;
	margin: 0;
}

.feature {
	display: flex;
	align-items: center;
	gap: 0.25rem;

	svg {
		width: 1rem;
		height: 1rem;
	}

	p {
		@include body7;
	}
}

.size_table {
	padding: 1rem 0 4rem;

	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		gap: 4rem;

		justify-items: center;

		max-width: 30rem;
		margin-inline: auto;
	}

	li {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1rem;

		> p {
			background-color: $neutral-5;

			color: #383838;
			text-align: center;
			font-size: 0.75rem;
			font-style: normal;
			font-weight: 500;
			line-height: 140%;

			display: inline-block;
			padding: 0.5rem 2rem;

			border-radius: 0.625rem;
		}
	}
}

.membership_titles {
	text-align: center;

	margin-inline: auto;
	margin-bottom: 2rem;

	h2 {
		@include subheading1;
		margin-bottom: 1rem;
	}

	p {
		@include body6;
		max-width: 82ch;
	}

	@include sm-down {
		h2 {
			@include subheading2;
			margin-bottom: 0.5rem;
		}
	}
}

.membership_why {
	border-radius: 10px;
	border: 1px solid $neutral-5;
	padding: 2rem;
	margin-bottom: 2rem;

	h2 {
		@include subheading4;
		margin-bottom: 1rem;
	}

	p {
		@include body6;
		color: $neutral-2;

		max-width: 80ch;

		&:not(:last-of-type) {
			margin-bottom: 1rem;
		}
	}

	@include sm-down {
		h2 {
			@include subheading4;
			margin-bottom: 1rem;
		}

		p {
			@include body7;

			&:not(:last-of-type) {
				margin-bottom: 1rem;
			}
		}
	}
}

.membership_grid_row {
	display: grid;
	grid-template-columns: 1fr 8rem 8rem;

	@include sm-down {
		grid-template-columns: 1fr 4rem 4rem;
	}
}

.membership_title {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	text-align: center;

	h3 {
		@include subheading3;
	}

	strong {
		@include body8;
		color: #d9682a;
	}

	.membership_monthly {
		@include body5;
	}

	.membership_yearly {
		@include body7;
		color: $neutral-2;
	}

	@include sm-down {
		h3 {
			@include subheading5;
		}

		strong {
			@include body8;
			color: #d9682a;
		}

		.membership_monthly {
			@include body6;
		}

		.membership_yearly {
			@include body8;
			color: $neutral-2;
		}
	}
}

.membership_features_list {
	border-radius: 10px;
	border: 1px solid $neutral-5;
	margin-top: 1rem;
	margin-bottom: 1rem;

	list-style: none;
}

.plan_feature_card {
	display: grid;
	grid-template-columns: 1fr 8rem 8rem;
	padding: 1rem 0;

	&:not(:last-of-type) {
		border-bottom: 1px solid $neutral-5;
	}

	@include sm-down {
		grid-template-columns: 1fr 4rem 4rem;
	}
}

.plan_icon_and_title {
	display: grid;
	grid-template-columns: 4rem 1fr;

	@include sm-down {
		display: block;
		margin: 0 1rem;
	}
}

.plan_feature_icon_container {
	margin: 0 1rem;

	display: flex;
	justify-content: center;
	align-items: flex-start;

	span {
		border: 1px solid $neutral-4;
		border-radius: 0.5rem;
		padding: 0.5rem;
		display: flex;
	}

	svg {
		width: 1.5rem;
		height: 1.5rem;
	}

	@include sm-down {
		display: none;
	}
}

.plan_feature_text {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: flex-start;
	justify-content: center;

	.plan_feature_title {
		@include subheading4;
		text-align: left;
	}

	.plan_feature_desc {
		@include body6;
		max-width: 60ch;
		color: $neutral-2;
	}

	@include sm-down {
		.plan_feature_title {
			@include subheading5;
		}

		.plan_feature_desc {
			@include body7;
			color: $neutral-2;
		}
	}
}

.feature_toggle {
	background: none;
	border: none;
	margin: 0;
	padding: 0;

	.chevron {
		margin: auto 0.5rem;
		transition: transform 0.25s ease-in-out;
	}

	svg {
		width: 1rem;
		height: 1rem;
	}
}

.feature_open {
	.chevron {
		transform: rotate(-180deg);
	}
}

.plan_feature_check {
	display: flex;
	justify-content: center;
	align-items: center;
	span,
	svg {
		display: inline-block;
		width: 1.5rem;
		height: 1.5rem;
	}
}

.plan_feature_special_message {
	display: flex;
	justify-content: center;
	align-items: center;

	p {
		@include body7;
		color: $neutral-2;
		text-align: center;
		max-width: 12.6ch;
	}

	@include sm-down {
		p {
			@include body8;
		}
	}
}
