@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';

.container {
	isolation: isolate;
	background-color: $neutral-white;
	color: $neutral-black;
	padding: 3.75rem 0;

	@include md-up {
		padding: 5.5rem 4.5rem;
	}
}

.header {
	padding: 0 1rem;
	display: flex;
	flex-direction: column-reverse;
	margin-bottom: 2rem;

	h2 {
		@include heading5;
		max-width: 11ch;
	}

	h3 {
		@include subheading4;
		color: $accent-5;
		margin-bottom: 0.625rem;
	}

	@include md-up {
		padding: 0;
		margin-bottom: 1.5rem;
		h2 {
			@include heading4;
			max-width: 20ch;
		}

		h3 {
			@include subheading2;
		}
	}
}

.learn_more {
	display: none;
	@include md-up {
		display: block;
	}

	margin-bottom: 2rem;
}

.cta_container {
	display: flex;
	justify-content: center;

	@include md-up {
		display: none;
	}
}

.cta_container2 {
	display: flex;
	margin-top: 60px;
	justify-content: center;
	align-items: center;
	gap: 20px;

	.link {
		font-size: 0.9em;
	}

	@include sm-down {
		margin-top: 20px;
	}
}

.mobile_hidden {
	@include sm-down {
		display: none !important;
	}
}

.cta {
	filter: invert(1); // TODO remove, and implement dark mode
}

.slider {
	padding: 0;
	list-style: none;
	margin: 0;

	@include md-up {
		display: none;
	}
}

.card {
	text-align: center;
	position: relative;

	&:not(:last-child) {
		margin-bottom: 2rem;
	}

	.phone_img {
		margin-top: -60px;
		display: block;
		margin-inline: auto;
		position: relative;
		width: 200px;
	}

	video {
		position: absolute;
		z-index: 10;
	}

	.img {
		display: block;
		height: 226px;

		img {
			height: 100%;
			max-height: unset;
		}
	}

	.title {
		@include body5;
		max-width: 37ch;
		padding: 0.625rem 1rem 2rem;
		margin-inline: auto;

		> a {
			display: none;
		}

		@include sm-down {
			padding: 0.625rem 1rem 1rem;

			> a {
				display: block;
			}
		}
	}
}

.vibrate_composite {
	width: 94px;
	right: 50%;
	transform: translate(50%, 25px);

	@include md-up {
		right: -5px;
		top: 110px;
		transform: none;
	}
}

.dial_composite {
	width: 111px;
	right: 50%;
	transform: translate(50%, 32px);

	@include md-up {
		right: -13px;
		top: 103px;
		transform: none;
	}
}

.stages_composite {
	width: 177px;
	right: 50%;
	transform: translate(50%, 7px);

	@include md-up {
		right: -44px;
		top: 70px;
		transform: none;
	}
}

.icon_container {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2rem 2rem 0;

	h3 {
		@include subheading3;
		text-align: center;
	}

	.icon_wrapper {
		display: none;
		padding: 0.5rem;
		border-radius: 6px;

		span {
			display: block;
			width: 2rem;
			height: 2rem;
		}
	}
}

.icon_wrapper {
	box-shadow: $neutral-3 0 0 0 1px;
}

.text_content {
	margin-left: 90px;
	display: grid;
	grid-template-columns: 5px auto;
	gap: 2rem;
}

.completion_lines {
	display: inline-block;
	width: 5px;
	height: 100%;
	background: $neutral-3;

	position: relative;

	> div {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 0;
		background: $neutral-black;
	}
}

.desktop_icon_container {
	display: flex;
	align-items: center;
	margin-bottom: 0.625rem;
	padding: 0;

	.icon_wrapper {
		display: none;
		padding: 0.25rem;
		border-radius: 4px;
		box-shadow: $neutral-3 0 0 0 1px;

		span {
			display: block;
			width: 1.5rem;
			height: 1.5rem;
		}
	}

	h3 {
		@include subheading2;
		// margin-left: 1rem;
		text-align: left;
	}
}

.desktop_item {
	padding: 0;
	text-align: left;

	&:not(:last-child) {
		margin-bottom: 3.5rem;
	}

	p {
		@include body4;
		max-width: 31ch;
	}

	width: 100%;
	justify-content: start;

	&:hover {
		li {
			opacity: 1 !important;
		}
	}

	li {
		transition: all 0.25s ease-in-out;
	}
}

.desktop_bar {
	position: relative;
	display: none;
	height: 415px;

	@include md-up {
		display: grid;
		grid-template-columns: 1fr 450px;
	}
}

.image_container {
	position: relative;
}

.desktop_slide {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	align-items: center;
	z-index: 0;

	transition: opacity 0.25s ease-in-out;

	video {
		position: absolute;
		z-index: 10;
	}
}

.lifestyle_img {
	display: block;
	width: 100%;
	img {
		height: 400px;
	}
}

.desktop_phone_img {
	position: absolute;
	max-height: 415px;
	top: 0;
	right: -60px;
}

.desktop_list {
	display: inline-block;
	list-style: none;
	padding: 0;
	margin: 0;
}
