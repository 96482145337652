@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';

.container {
	background-color: $neutral-white;
	color: $neutral-black;
	max-width: 75rem;
	margin: auto;
	padding: 0 2rem;
	display: flex;
	align-items: center;
	column-gap: 90px;
}

.header_container {
	display: flex;
	justify-content: center;
	flex-direction: column;

	img {
		max-width: 230px;

		@include xs-only {
			margin: 0 auto;
		}
	}

	p {
		color: $solid-blue;
		text-transform: uppercase;
		@include body7;
		letter-spacing: 0.125rem;
		text-align: left;
		margin-bottom: 1rem;

		@include xs-only {
			text-align: center;
		}
	}

	h2 {
		@include subheading1;
		text-align: left;
		margin-bottom: 1.5rem;

		@include xs-only {
			text-align: center;
		}
	}

	@include md-up {
		p {
			display: inline-block;
			border-radius: 5px;
			padding: 0.25rem 0.5rem;
			@include subheading5;
			text-align: left;
			background: linear-gradient(91deg, rgba(1, 78, 168, 0.05) -14.01%, rgba(1, 59, 139, 0.05) 53.53%, rgba(1, 42, 109, 0.05) 115.67%);
			margin-bottom: 1.5rem;
		}

		h2 {
			@include heading5;
			text-align: left;
			margin-bottom: 1.5rem;
		}
	}

	span {
		font-size: 16px;
		font-weight: 400;
		max-width: 400px;

		@include xs-only {
			text-align: center;
		}
	}

	@include xs-only {
		text-align: center;
	}
}

.bottom_info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	text-align: center;
	margin-top: 40px;

	@include xs-only {
		align-items: center;
	}
}

.cta_button {
	margin-bottom: 1.5rem;

	@include md-up {
		margin-bottom: 2rem;
	}
}

.divider {
	&::after {
		position: absolute;
		content: '';
		background: #d2d3d5;
		width: 1px;
		height: 50%;
		right: -1rem;

		@include md-up {
			right: -2rem;
		}
	}
}

.terms {
	@include body7;
	color: #898989;
	text-align: left;
	opacity: 0.7;
	line-height: 1.8;

	a {
		color: $solid-blue;
		text-decoration: underline;
		cursor: pointer;
	}

	@include xs-only {
		text-align: center;
	}
}

.mobile_image_container {
	margin-top: 25px;

	img {
		max-height: 16rem;
	}

	@include md-up {
		display: none;
	}
}

.desktop_image_container {
	@include sm-down {
		display: none;
	}
}

.hightlight {
	color: #d96829;
	font-weight: 700;
	font-size: 18px;
}

.content_container {
	margin-top: 40px;

	@include xs-only {
		margin-top: 30px;
		text-align: center;
	}
}

.pricing {
	margin-top: 15px;
	display: flex;
	align-items: center;
	max-width: 300px;
	gap: 30px;

	@include xs-only {
		margin: 15px auto 0;
	}

	> div {
		display: flex;
		flex-direction: column;
		row-gap: 5px;

		&:first-of-type {
			border-right: 1px solid #797c8e;
			padding-right: 30px;
		}

		strong {
			font-size: 20px;
			font-weight: 700;
			display: flex;
			align-items: center;
			gap: 10px;
		}

		em {
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			color: #797c8e;
			text-decoration: line-through;
		}

		span {
			font-size: 12px;
			opacity: 0.6;
		}
	}
}
