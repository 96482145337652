@import 'styles/mixins/media-queries';
@import 'styles/colors-2023';
@import 'styles/layout-tokens';
// @import 'styles/mixins/fonts-2023';
// @import 'styles/mixins/button-styles-2023';
@import 'styles/colors-2024';
@import 'styles/mixins/fonts-2024';
.content {
	background-color: #fff;
	color: #000;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.features {
	display: flex;
	justify-content: space-between;
	margin-bottom: 3rem;
	flex-wrap: wrap;
	width: 294px;
	margin-left: auto;
	margin-right: auto;
	li {
		display: flex;
		flex-direction: column;
		flex-basis: 50%;
		align-items: center;
		&:first-child,
		&:nth-child(2) {
			margin-bottom: 2rem;
		}
	}

	svg {
		width: 1.5rem;
		height: 1.5rem;
	}

	p {
		@include body5;
		text-align: center;
		max-width: 460px;
	}
}

.mattress_layers {
	display: flex;
	justify-content: flex-end;

	max-height: 350px;
	@include md-up {
		//	margin-left: 6rem;
		img {
			max-width: 460px;
		}
	}
}

.disclaimer {
	@include body3;
	color: #a6a6a6;
	text-align: center;
	margin-bottom: 4rem;
}

.mattresstitle {
	@include headline6;
	text-align: center;
	max-width: 22ch;
	margin-bottom: 1.5rem;
	@include xs-only {
		@include headline7;
		margin-bottom: 1.5rem;
		max-width: 16ch;
	}
}

.mm {
	color: #000;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	//margin-right: -1.5rem;

	.mattresstitle {
		max-width: 460px;
		@include xs-only {
			max-width: 250px;
			font-size: 1.5rem;
		}
	}

	.description {
		max-width: 460px;
		font-size: 1.125rem;
		@include xs-only {
			max-width: 242px;
			width: auto;
		}
	}
}
.description {
	@include body5;
	color: #a6a6a6;
	text-align: center;
	margin-bottom: 3.5rem;
	width: 35ch;
	@include xs-only {
		margin-bottom: 1.5rem;
		width: 24ch;
	}
}
