@import 'styles/mixins/media-queries';
@import 'styles/mixins/pod-3-fonts';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';
@import 'styles/layout-tokens';

.container {
	padding-bottom: 60px;
	padding-top: 6rem;
	height: 100vh;
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;
	isolation: isolate;

	@include md-up {
		flex-direction: row;
		padding-bottom: 0;
		padding-top: 0;
		height: calc(100vh - $nav-height-desktop);
	}

	isolation: isolate;
}

.shade {
	position: absolute;
	inset: 0;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 30%, rgba(0, 0, 0, 0) 100%);

	@include md-up {
		background: radial-gradient(circle at 100% 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.25) 100%);
	}
}

.full_bleed_image {
	position: absolute;
	z-index: -1;
	inset: 0;

	picture,
	img,
	figure,
	video {
		object-fit: cover;
		width: 100%;
		height: 100%;

		@include sm-down {
			object-position: bottom;
		}
	}
}

.content {
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
	flex-direction: column;
	margin: 4rem 0 0;

	@include md-up {
		align-items: unset;
		margin: calc($nav-height-desktop + $promo-height + 2.5rem) 5rem 5rem;
	}
}

.superheader {
	@include body7;
	margin-bottom: 0.75rem;
	text-align: center;

	text-transform: uppercase;
	letter-spacing: 0.1875rem;

	@include md-up {
		text-align: left;
		@include body5;
		margin-bottom: 1rem;
		letter-spacing: 0.1875rem;
	}
}

.header {
	@include heading5;
	margin-bottom: 1.5rem;
	text-align: center;

	@include md-up {
		@include heading3;
		text-align: left;
		margin-bottom: 1.5rem;
	}

	max-width: 12ch;
}

p.description {
	max-width: 400px;
}

.anchor_link_wrapper {
	width: 3rem;
	height: 3rem;

	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 100%;

	* {
		transition: filter 0.5s ease-in-out;
	}

	img {
		width: 3rem;
		height: 3rem;
	}

	z-index: 300;
	position: absolute;
	bottom: 4rem;
	left: 50%;
	transform: translateX(-50%);

	transition: all 0.5s ease-in-out;
}
