@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';

.container {
	isolation: isolate;
	color: $neutral-white;
	background-color: $neutral-black;
	padding: 3.75rem 0;

	text-align: center;

	h2 {
		padding: 0 1rem;
		@include subheading1;
		max-width: 20ch;
		margin: auto;
	}

	@include md-up {
		h2 {
			@include heading4;
			max-width: 20ch;
			margin: auto;
		}
	}
}

.cta_button {
	display: block;
	margin-top: 15px;

	> a {
		color: white;
	}
}

.marquee {
	&:not(:last-child) {
		margin-bottom: 2rem;
	}
}

.card {
	width: 160px;
	height: 160px;
	position: relative;
	margin: 0 1rem;

	@include md-up {
		width: 240px;
		height: 240px;
	}
}

.background {
	position: absolute;
	inset: 0;
	z-index: -1;
}

.shade {
	background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 80%) 100%);
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 50%;
	z-index: 1;
}

.text {
	position: relative;
	z-index: 1;
	text-align: left;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 0.5rem;
}

.name {
	@include subheading5;
}

.description {
	@include body7;
}

.img {
	img {
		width: 100%;
		height: 100%;
	}
}

.cta {
	margin-top: 1rem;
}

.text_container {
	margin-bottom: 2rem;
	@include md-up {
		margin-bottom: 4rem;
	}
}

.ctas {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	gap: 1rem;

	margin: 0 2rem;
}
