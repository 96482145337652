@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';
@import 'styles/mixins/button-styles-2023';
@import 'styles/layout-tokens';

.container {
	color: $neutral-black;

	position: relative;
	z-index: 1;
	overflow: hidden;

	transition: all 0.7s ease-in-out;

	padding-top: 3.75rem;

	@include md-up {
		height: min(60rem, 100vh - $nav-height-desktop);

		display: flex;
		align-items: center;
	}
}

.content_container {
	position: relative;
	z-index: 5;
}

.text_container {
	color: $neutral-black;
	text-align: center;

	h2 {
		@include heading5;
		margin-bottom: 1rem;
		max-width: 15ch;
		margin-left: auto;
		margin-right: auto;
		margin-inline: auto;
	}

	p {
		@include body4;
		max-width: 30ch;
		margin-inline: auto;
		margin-bottom: 1rem;
	}

	margin-bottom: 3.5rem;

	@include md-up {
		color: $neutral-white;
		text-align: left;
		padding: 5rem;

		h2 {
			@include heading4;
			margin-bottom: 1rem;
			max-width: 17ch;
		}

		p {
			@include body3;
			max-width: 38ch;
			margin-left: 0;
			margin-bottom: 1rem;
		}

		margin-bottom: 0;
	}
}

.cta {
	filter: invert(1);

	@include md-up {
		filter: none;
	}
}

.bg_container {
	position: absolute;
	inset: 0;

	@include sm-down {
		position: relative;
		width: 100vw;
		height: min(50rem, 60vh);
	}
}

.full_bg {
	position: absolute;
	inset: 0;
	z-index: -2;
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.stats {
	background: rgba(23, 25, 32, 0.5);
	backdrop-filter: blur(7px);
	border-radius: 10px;

	position: absolute;
	width: min(20rem, 90vw);
	z-index: 2;

	opacity: 1;

	top: 10%;
	right: 50%;
	transform: translate(50%, 0);

	@include md-up {
		top: 20%;
		right: 20%;

		transform: none;
	}
}

.shade {
	position: absolute;
	inset: 0;
	z-index: -1;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(70deg, rgba(0, 0, 0, 0.45) 1.03%, rgba(0, 0, 0, 0) 82.69%);
}
