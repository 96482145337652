@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts_legacy';

.section {
	background-color: #000;

	.pro_slider {
		padding-top: 35px;
		padding-bottom: 35px;
		background-color: transparent;
		opacity: 0;

		img {
			height: 72px;

			@include md-up {
				max-width: 200px;
			}
		}

		p {
			max-width: 130px;
			color: #fff;
			opacity: 1;

			&:empty {
				height: 0;
			}
		}

		:global(.slick-slide) {
			display: flex;
			justify-content: center;
		}

		@include md-up {
			:global(.slick-list) {
				overflow: visible;
			}
			:global(.slick-slide) {
				transition: all 0.3s ease;
				opacity: 1;

				&:hover {
					opacity: 0.7;
					// transform: scale(1.04);
				}
			}
		}

		&.mounted {
			transition: opacity 0.2s ease-in-out;
			opacity: 1;
		}
	}
}
